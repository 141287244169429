import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
//import Home from '@/views/Home'
const Home = () => import('@/views/Home')
//import Contact from '@/views/Contact'
const Contact = () => import('@/views/Contact')
//import MediaBookList from '@/views/medias/MediaBookList'
const MediaBookList = () => import('@/views/medias/MediaBookList')
const MediaBookDetail = () => import('@/views/medias/MediaBookDetail')
//import MediaBookDetail from '@/views/medias/MediaBookDetail'
const MediaDetail = () => import('@/views/medias/MediaDetail')
//import MediaDetail from '@/views/medias/MediaDetail'
//import Login from '@/views/Login'
const Login = () => import('@/views/Login')
//import Register from '@/views/Register'
const Register = () => import('@/views/Register')
//import RegisterSimple from '@/views/RegisterSimple'
const RegisterSimple = () => import('@/views/RegisterSimple')
//import RequestChangePassword from '@/views/RequestChangePassword'
const RequestChangePassword = () => import('@/views/RequestChangePassword')
//import ChangePassword from '@/views/ChangePassword'
const ChangePassword = () => import('@/views/ChangePassword')
//import AssociateCode from '@/views/AssociateCode'
const AssociateCode = () => import('@/views/AssociateCode')
//import Session from '@/views/Session'
const Session = () => import('@/views/Session')
//import Profile from '@/views/Profile'
const Profile = () => import(/* webpackChunkName: 'group-profile' */ '@/views/Profile')
//import AlbumList from '@/views/albums/AlbumList';
const AlbumList = () => import(/* webpackChunkName: 'group-albums' */ '@/views/albums/AlbumList')
//import AlbumDetail from '@/views/albums/AlbumDetail';
const AlbumDetail = () => import(/* webpackChunkName: 'group-albums' */ '@/views/albums/AlbumDetail')
//import UserPlayListDetail from '@/views/albums/UserPlayListDetail';
const UserPlayListDetail = () => import(/* webpackChunkName: 'group-albums' */ '@/views/albums/UserPlayListDetail')
//import ArticleCategoriesDashboard from '@/views/articles/ArticleCategoriesDashboard';
const ArticleCategoriesDashboard = () => import(/* webpackChunkName: 'group-articles' */ '@/views/articles/ArticleCategoriesDashboard')
//import ArticleDetail from '@/views/articles/ArticleDetail';
const ArticleDetail = () => import(/* webpackChunkName: 'group-articles' */ '@/views/articles/ArticleDetail')
//import SchoolList from '@/views/schools/SchoolList';
const SchoolList = () => import(/* webpackChunkName: 'group-schools' */ '@/views/schools/SchoolList')
//import SchoolDetail from '@/views/schools/SchoolDetail';
const SchoolDetail = () => import(/* webpackChunkName: 'group-schools' */ '@/views/schools/SchoolDetail')
//import VideosGalleryList from '@/views/videos/VideosGalleryList';
const VideosGalleryList = () => import(/* webpackChunkName: 'group-videos' */ '@/views/videos/VideosGalleryList')
//import VideoByGalleryList from '@/views/videos/VideoByGalleryList';
const VideoByGalleryList = () => import(/* webpackChunkName: 'group-videos' */ '@/views/videos/VideoByGalleryList')
//import VideoDetail from '@/views/videos/VideoDetail';
const VideoDetail = () => import(/* webpackChunkName: 'group-videos' */ '@/views/videos/VideoDetail')
//import TaskList from '@/views/tasks/TaskList';
const TaskList = () => import(/* webpackChunkName: 'group-tasks' */ '@/views/tasks/TaskList')
//import TaskDetail from '@/views/tasks/TaskDetail';
const TaskDetail = () => import(/* webpackChunkName: 'group-tasks' */ '@/views/tasks/TaskDetail')
const PresentationCategoryList = () => import('@/views/presentations/PresentationCategoryList')
//import PresentationCategoryList from '@/views/presentations/PresentationCategoryList';
const PresentationByCategoryList = () => import('@/views/presentations/PresentationByCategoryList')
//import PresentationByCategoryList from '@/views/presentations/PresentationByCategoryList';
const PresentationDetail = () => import('@/views/presentations/PresentationDetail')
//import PresentationDetail from '@/views/presentations/PresentationDetail';
const ScoreCategoryList = () => import('@/views/scores/ScoreCategoryList')
//import ScoreCategoryList from '@/views/scores/ScoreCategoryList';
const ScoreByCategoryList = () => import('@/views/scores/ScoreByCategoryList')
//import ScoreByCategoryList from '@/views/scores/ScoreByCategoryList';
const ScoreDetail = () => import('@/views/scores/ScoreDetail');
//import ScoreDetail from '@/views/scores/ScoreDetail';
//import ExerciseCategoryList from '@/views/exercises/ExerciseCategoryList';
const ExerciseCategoryList = () => import('@/views/exercises/ExerciseCategoryList')
//import ExerciseByCategoryList from '@/views/exercises/ExerciseByCategoryList';
const ExerciseByCategoryList = () => import('@/views/exercises/ExerciseByCategoryList')
//import ExerciseDetail from '@/views/exercises/ExerciseDetail';
const ExerciseDetail = () => import('@/views/exercises/ExerciseDetail')
//import LegalAdvice from '@/views/LegalAdvice.vue';
const LegalAdvice = () => import('@/views/LegalAdvice')
//import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
const PrivacyPolicy = () => import('@/views/PrivacyPolicy')
//import CookiesPolicy from '@/views/CookiesPolicy.vue';
const CookiesPolicy = () => import('@/views/CookiesPolicy')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    alias: '/home',
    meta: {
      is_private: false,
      is_main: true
    }
  },
  {
    path: '/albums',
    name: 'albums',
    meta: {
      is_private: false,
      is_main: false
    },
    component: AlbumList
  },
  {
    path: '/albums/:id',
    name: 'albums-detail',
    meta: {
      is_private: false,
      is_main: false
    },
    component: AlbumDetail
  },
  {
    path: '/playlist',
    name: 'playlist',
    meta: {
      is_private: true,
      is_main: false
    },
    component: UserPlayListDetail
  },
  {
    path: '/schools',
    name: 'schools',
    meta: {
      is_private: false,
      is_main: false
    },
    beforeEnter: (to, from, next) => {
      if (store.state.isAuth && store.state.user.school_id > 0) {
        if (from.name === 'school-detail') {
          next({name: 'home'})
        } else {
          //const path = `/schools/${store.state.user.school_id}`
          next({name: 'school-detail', params: {id: store.state.user.school_id}})
        }
      } else {
        next();
      }
    },
    component: SchoolList
  },
  {
    path: '/schools/:id',
    name: 'school-detail',
    meta: {
      is_private: false,
      is_main: false
    },
    component: SchoolDetail
  },
  {
    path: '/articles',
    name: 'articles',
    meta: {
      is_private: false,
      is_main: false
    },
    component: ArticleCategoriesDashboard
  },
  {
    path: '/article/:id',
    name: 'articles-detail',
    meta: {
      is_private: false,
      is_main: false
    },
    component: ArticleDetail
  },
  {
    path: '/presentations',
    name: 'presentations',
    meta: {
      is_private: false,
      is_main: false
    },
    component: PresentationCategoryList
  },
  {
    path: '/presentations-category/:id',
    name: 'presentations-category',
    meta: {
      is_private: false,
      is_main: false
    },
    component: PresentationByCategoryList
  },
  {
    path: '/presentation/:id',
    name: 'presentations-detail',
    meta: {
      is_private: false,
      is_main: false
    },
    component: PresentationDetail
  },
  {
    path: '/score-books',
    name: 'score-books',
    meta: {
      is_private: false,
      is_main: false
    },
    component: ScoreCategoryList
  },
  {
    path: '/score-books/:id',
    name: 'score-book-detail',
    meta: {
      is_private: false,
      is_main: false
    },
    component: ScoreByCategoryList
  },
  {
    path: '/score/:id',
    name: 'score-detail',
    meta: {
      is_private: false,
      is_main: false
    },
    component: ScoreDetail
  },
  {
    path: '/exercise-books',
    name: 'exercise-books',
    meta: {
      is_private: false,
      is_main: false
    },
    component: ExerciseCategoryList
  },
  {
    path: '/exercise-books/:id',
    name: 'exercise-book-detail',
    meta: {
      is_private: false,
      is_main: false
    },
    component: ExerciseByCategoryList
  },
  {
    path: '/exercise/:id',
    name: 'exercise-detail',
    meta: {
      is_private: false,
      is_main: false
    },
    component: ExerciseDetail
  },
  {
    path: '/tasks',
    name: 'tasks',
    meta: {
      is_private: true,
      is_main: false
    },
    component: TaskList
  },
  {
    path: '/tasks/:slug',
    name: 'tasks-detail',
    meta: {
      is_private: true,
      is_main: false
    },
    component: TaskDetail
  },
  {
    path: '/videos',
    name: 'videos',
    meta: {
      is_private: false,
      is_main: false
    },
    component: VideosGalleryList
  },
  {
    path: '/videos/:galleryId',
    name: 'videos-gallery',
    meta: {
      is_private: false,
      is_main: false
    },
    component: VideoByGalleryList
  },
  {
    path: '/videos/watch/:id',
    name: 'video-detail',
    meta: {
      is_private: false,
      is_main: false
    },
    component: VideoDetail
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      is_private: false,
      is_main: false
    },
    component: Contact
  },
  {
    path: '/media-books',
    name: 'media-books',
    meta: {
      is_private: false,
      is_main: false
    },
    component: MediaBookList
  },
  {
    path: '/media-books/:bookId',
    name: 'media-book-detail',
    meta: {
      is_private: false,
      is_main: false
    },
    component: MediaBookDetail
  },
  {
    path: '/media/:id',
    name: 'media-detail',
    meta: {
      is_private: false,
      is_main: false
    },
    component: MediaDetail
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      is_private: false,
      is_main: false
    },
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/register-simple',
    name: 'register-simple',
    component: RegisterSimple
  },
  {
    path: '/forgotten-password',
    name: 'forgotten-password',
    meta: {
      is_private: false,
      is_main: false
    },
    component: RequestChangePassword
  },
  {
    path: '/change-password/:token',
    name: 'change-password',
    meta: {
      is_private: false,
      is_main: false
    },
    component: ChangePassword
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      is_private: true,
      is_main: false
    },
    component: Profile
  },
  {
    path: '/associate-code',
    name: 'associate-code',
    meta: {
      is_private: true,
      is_main: false
    },
    component: AssociateCode
  },
  {
    path: '/session/:sessionId',
    name: 'handle-session',
    meta: {
      is_private: false,
      is_main: false
    },
    component: Session
  },
  {
    path: '/legal-advice',
    name: 'legal-advice',
    meta: {
      is_private: false,
      is_main: false
    },
    component: LegalAdvice
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    meta: {
      is_private: false,
      is_main: false
    },
    component: PrivacyPolicy
  },
  {
    path: '/cookies-policy',
    name: 'cookies-policy',
    meta: {
      is_private: false,
      is_main: false
    },
    component: CookiesPolicy
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err
  });
}

router.beforeEach((to, from, next) => {
  if (to.meta.is_private && !store.state.isAuth) {
    next(`login?redirect_to=${to.fullPath}`);
  } else {
    next();
  }
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})

export default router
