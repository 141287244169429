import API from './base'
import {Deserializer} from 'jsonapi-serializer'
const JSONAPIDeserializer = new Deserializer({ keyForAttribute: 'underscore_case' })

export default class Media extends API {
  async getBookList() {
      try {
        const url = `medias/books`
        const response = await this.axios.get(url)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async getBook(bookId) {
      try {
        const url = `medias/books/${bookId}`
        const response = await this.axios.get(url)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }

  async getMedia(mediaId) {
      try {
        const url = `medias/${mediaId}`
        const response = await this.axios.get(url)
        const result = await JSONAPIDeserializer.deserialize(response.data)
        return Promise.resolve(result)
      } catch (err) {
        return Promise.reject(err)
      }
  }
}
